<template>
    <mercur-card class="fill full-height-layout">
        <grid-header :quickSearch.sync="filters.search">
            Agreements
            <template slot="actions">
                <mercur-button :to="{name: 'AgreementAdd'}" class="btn btn-raised btn-yellow text-uppercase">
                    <i class="fas fa-plus"></i>
                    <span>Add new agreement</span>
                </mercur-button>
            </template>
        </grid-header>

        <data-table
            class="fill full-height-layout border"
            :options="options"
            :url="url"
            :quickSearch="filters.search"
            v-if="isAllowedTo('getAllAgreements')"
        ></data-table>
    </mercur-card>
</template>

<script>
import DataTable from '@/components/DataTable'
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'

export default {
    name: 'AgreementsOverview',
    components: { DataTable, GridHeader },
    data () {
        return {
            options: {
                columns: {
                    'Name': {
                        field: 'agreementName',
                        sortable: true,
                        link: (value, data) => {
                            return {
                                name: 'AgreementEdit',
                                params: {
                                    agreementId: data.agreementId,
                                },
                            }
                        },
                    },
                    'Status': {
                        field: 'agreementStatus',
                        statusChip: true,
                    },
                    'Task (status)': {
                        field: 'taskStatus',
                        statusChip: true,
                        cellRendererParams ({ data }) {
                            return {
                                text: `${data.taskType} (${data.taskStatus})`,
                                statusChipSize: 'small',
                            }
                        },
                    },
                    'Created': {
                        field: 'dateCreated',
                        sortable: true,
                    },
                },
                actions: [
                    {
                        icon: 'fas fa-eye',
                        text: 'View',
                        to: params => ({
                            name: 'AgreementEdit',
                            params: {
                                agreementId: params.data.agreementId,
                            },
                        }),
                    },
                ],

                quickSearchColumns: ['agreementName'],
            },
            url: CONFIG.API.ROUTES.AGREEMENTS.OVERVIEW,
            filters: {
                search: '',
            },
            supplierPopupActive: false,
        }
    },

    methods: {
        closeSupplierAddPopup () {
            this.supplierPopupActive = false
        },

        saveSupplierAddPopup (response, values) {
            this.supplierPopupActive = false
            this.$router.push({
                name: 'SupplierOnboardView',
                params: {
                    supplierId: response.data.supplierId,
                    ...values,
                },
            })
        },

        getSupplierEditLink (supplier) {
            return {
                name: 'SupplierOnboardView',
                params: {
                    supplierId: supplier.supplierId,
                },
            }
        },
    },
}
</script>
